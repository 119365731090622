import { createSlice } from '@reduxjs/toolkit'

export const porfileSlice = createSlice({
    name: 'error',
    initialState: {
        id: 0,
        userUuid: '',
        email: '',
        firstName: {en: '-', th: '-'},
        lastName: {en: '-', th: '-'},
        occupation: '-',
        photo: '/images/default-user-profile.png',
        isPersonalCheck: false,
        isExperienceCheck: false,
        isEducationCheck: false,
        isAddressCheck: false,
        isResumeCheck: false,
    },
    reducers:{
        setProfile: (state, { payload }) => {
            state = {
                ...state,
                ...payload,
            }
            return state
        },
        setUsername: (state, { payload }) => {
            state.firstName = payload.firstName
            state.lastName = payload.lastName
        },
    },
})

export const { 
    setProfile,
    setUsername,
} = porfileSlice.actions

export default porfileSlice.reducer
