import { createSlice } from '@reduxjs/toolkit'

export const carmeraSlice = createSlice({
    name: 'camera',
    initialState: {
        open: false,
        cameraStatus: false,
        microphoneStatus: false,
        startRecord: false,
        callback: () => null,
    },
    reducers:{
        setCamera: (state, { payload }) => {
            state = payload
            return state
        },
    },
})

export const { 
    setCamera,
} = carmeraSlice.actions

export default carmeraSlice.reducer
