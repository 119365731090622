// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './auth'
import message from './message'
import mediaQuery from './mediaQuery'
import profile from './profile'
import camera from './camera'
const rootReducer = {
    auth,
    navbar,
    layout,
    message,
    mediaQuery,
    profile,
    camera,
}

export default rootReducer
