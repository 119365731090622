import { createSlice } from '@reduxjs/toolkit'

export const mediaQuery = createSlice({
    name: 'mediaQuery',
    initialState: {
        size: 'lg',
        xlDown: false,
        lgDown: false,
        mdDown: false,
        smDown: false,
        xsDown: false,

        xlUp: false,
        lgUp: false,
        mdUp: false,
        smUp: false,
        xsUp: false,
    },
    reducers:{
        updateMediaQuery: (state, { payload }) => {
            if (payload >= 1440) {
                state.size = 'xl'
                state.xlDown = false
                state.lgDown = false
                state.mdDown = false
                state.smDown = false
                state.xsDown = false

                state.xlUp = true
                state.lgUp = true
                state.mdUp = true
                state.smUp = true
                state.xsUp = true
            } else if (payload >= 991) {
                state.size = 'lg'
                state.xlDown = true
                state.lgDown = false
                state.mdDown = false
                state.smDown = false
                state.xsDown = false

                state.xlUp = false
                state.lgUp = true
                state.mdUp = true
                state.smUp = true
                state.xsUp = true
            } else if (payload >= 768) {
                state.size = 'md'
                state.xlDown = true
                state.lgDown = true
                state.mdDown = false
                state.smDown = false
                state.xsDown = false

                state.xlUp = false
                state.lgUp = false
                state.mdUp = true
                state.smUp = true
                state.xsUp = true
            } else if (payload >= 375) {
                state.size = 'sm'
                state.xlDown = true
                state.lgDown = true
                state.mdDown = true
                state.smDown = false
                state.xsDown = false

                state.xlUp = false
                state.lgUp = false
                state.mdUp = false
                state.smUp = true
                state.xsUp = true
            } else if (payload >= 320) {
                state.size = 'xs'
                state.lgDown = true
                state.mdDown = true
                state.smDown = true
                state.xsDown = false

                state.xlUp = false
                state.lgUp = false
                state.mdUp = false
                state.smUp = false
                state.xsUp = true
            }
        },
    },
})

export const { updateMediaQuery } = mediaQuery.actions

export default mediaQuery.reducer
