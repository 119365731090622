// ** I18n Imports

import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n

// Enables the i18next backend
    .use(Backend)

// Enable automatic language detection
    .use(LanguageDetector)

// Enables the hook initialization module
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem('i18nextLng'),
        // lng: 'th',
        backend: {
            /* translation file path */
            loadPath: '/assets/data/locales/{{lng}}.json',
        },
        // defaultNS: 'en',
        fallbackLng: 'en',
        supportedLngs: ['th', 'en'],
        debug: false,
        react: {
            useSuspense: false,
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
    })

export default i18n
